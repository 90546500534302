// Offer.js
import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import '../Styles/Offer.css';

const Offer = () => {
  const [isVisible, setIsVisible] = useState(false);
  const currentYear = new Date().getFullYear();
  const experienceYears = currentYear - 2015; // Начальный год - 2015

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), 100);

    return () => clearTimeout(timer); 
  }, []);

 
  return (
    <div className="home">
      <Sidebar />
      <div className={`offer-content fade-in ${isVisible ? 'active' : ''}`}>
        <header className="offer-header">
          <h2>What I Offer</h2>
       

        </header>

        <section className="offer-intro">
        <p className="intro-text">
  <span className="highlight">As a Full-Stack Developer</span> and <span className="highlight">Cloud Solutions Architect</span> with over
  <span className="highlight"> {experienceYears} years of experience</span>, I specialize in designing and delivering <strong>scalable</strong>, 
  <strong>high-availability</strong>, and <strong>cost-efficient</strong> cloud-based architectures, along with <strong>end-to-end software solutions</strong>.
</p>
<p className="intro-text">
  My expertise spans <span className="highlight">multi-cloud and hybrid environments</span>, ensuring <strong>reliability</strong>, <strong>security</strong>, and <strong>scalability</strong> across complex systems.
</p>

</section>


        <section className="offer-tech-stack">
          <h3 className="section-title">Current Tech Stack</h3>
          <div className="tech-stack-grid">
            <div className="tech-category">
              <span className="category-title">Front-End:</span>
              <p>React, TypeScript</p>
            </div>
            <div className="tech-category">
              <span className="category-title">Back-End:</span>
              <p>Node.js, ExpressJS, Python, Redis, MongoDB, PostgreSQL</p>
            </div>
            <div className="tech-category">
  <span className="category-title">DevOps & Cloud Architecture:</span>
  <p>GCP, AWS, Azure, Terraform, Kubernetes, CI/CD Pipelines, Grafana, Prometheus, Helm, ArgoCD, Serverless, Autoscaling, Cloud-Native Applications</p>
</div>

    <div className="tech-category">
      <span className="category-title">Architectural Tools:</span>
      <p>Enterprise Architect, Azure Resource Manager, Visio, draw.io, Miro</p>
    </div>

            <div className="tech-category">
              <span className="category-title">Tools & Platforms:</span>
              <p>Jira, Confluence, Docker Compose, Docker Git, Postman, Swagger, Figma, Canva</p>
            </div>
            <div className="tech-category">
              <span className="category-title">Languages:</span>
              <p>JavaScript, Python, Swift, TypeScript</p>
            </div>
            <div className="tech-category">
              <span className="category-title">Methodologies:</span>
              <p>Agile, Scrum, Kanban, TDD (Test-Driven Development)</p>
            </div>
            <div className="tech-category">
              <span className="category-title">Mobile Development:</span>
              <p>Swift, React Native</p>
            </div>
            <div className="tech-category">
              <span className="category-title">ITSM (IT Service Management):</span>
              <p>ServiceNow</p>
            </div>
          </div>
        </section>


        <section className="offer-section">
          <h3>
            <img src="/frontend.png" alt="Frontend Icon" className="section-icon" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} />
            Front-End Web Development
          </h3>
          <p>I specialize in creating modern and responsive web applications using React.</p>
          <ul>
            <li>Expertise in TypeScript/JavaScript</li>
            <li>Advanced CI/CD practices, including automated testing and deployment pipelines</li>
            <li>Enhancing user experience with intuitive and accessible designs</li>
            <li>Expertise in creating responsive and mobile-first designs</li>
            <li>Libraries like Redux, React, and other modern front-end tools</li>
          </ul>
          <div className="statistics">
            <div className="stat-item">
              <span className="stat-number">20+</span>
              <span className="stat-label">SPA launched</span>
            </div>
            <div className="stat-item">
              <span className="stat-number">95%</span>
              <span className="stat-label">User satisfaction in usability tests</span>
            </div>
            <div className="stat-item">
              <span className="stat-number">~40</span>
              <span className="stat-label">Code reviews</span>
            </div>
          </div>
        </section>

        <section className="offer-section">
          <h3>
            <img src="/backend.png" alt="Backend Icon" className="section-icon" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} />
            Back-End Web Development
          </h3>
          <p>I develop scalable and efficient back-ends using Node.js, Flask, Python, Docker, and Kubernetes.</p>
          <ul>
            <li>Microservices architecture</li>
            <li>Database optimization</li>
            <li>Event-driven system design</li>
            <li>Secure authentication and authorization with OAuth2, JWT, and SSO</li>
            <li>Integration of REST and GraphQL APIs</li>
            <li>Cloud-based serverless architectures (AWS Lambda, Azure Functions)</li>
          </ul>
          <div className="statistics">
            <div className="stat-item">
              <span className="stat-number">35+</span>
              <span className="stat-label">Backend systems delivered</span>
            </div>
            <div className="stat-item">
              <span className="stat-number">9</span>
              <span className="stat-label">Microservices developed</span>
            </div>
            <div className="stat-item">
              <span className="stat-number">40%</span>
              <span className="stat-label">Improved database query performance</span>
            </div>
          </div>
        </section>

        <section className="offer-section">
          <h3 className="section-title">Why Choose Me?</h3>
          <ul className="highlight-list">
            <li>Deep understanding of back-end scalability and fault tolerance</li>
            <li>Experience with real-time data processing and streaming</li>
            <li>Proven track record of reducing server response times by over 40%</li>
            <li>Expertise in debugging and optimizing legacy systems</li>
            <li>Clear communication and excellent project management skills</li>
            <li>Commitment to delivering maintainable and high-quality code</li>
          </ul>
        </section>


        <section className="offer-services">
          <h3 className="section-title">Additional Services</h3>
          <ul className="services-list">
            <li>
              <img src="/consulting.png" alt="Consulting Icon" className="service-icon" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} />
              Strategic consulting, tech stack evaluation, and architectural planning
            </li>
            <li>
              <img src="/code-review.png" alt="Code Review Icon" className="service-icon" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} />
              Code review and team mentoring
            </li>
            <li>
              <img src="/ui-ux.png" alt="UI/UX Icon" className="service-icon" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} />
              UI/UX improvements and design consultations
            </li>
          </ul>
        </section>

      </div>
    </div>
  );
};

export default Offer;
