// HireMe.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';
import '../Styles/Hireme.css';

const HireMe = () => {
  const [isPageVisible, setIsPageVisible] = useState(false);
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  useEffect(() => {
    const pageTimer = setTimeout(() => setIsPageVisible(true), 100);
    const infoTimer = setTimeout(() => setIsInfoVisible(true), 200);

    return () => {
      clearTimeout(pageTimer);
      clearTimeout(infoTimer);
    };
  }, []);

  return (
    <div className="home">
      <Sidebar />
      <div className={`Hireme-content fade-in-page ${isPageVisible ? 'active' : ''}`}>
      <h2>Hire Me</h2>
      <p>
      I’m excited to announce that I’m <span className="highlight">looking for new opportunities</span>! 
      I’m a <strong>Full-Stack Developer</strong> and <strong>Cloud Solutions Architect</strong> with 
      a passion for building things that make a real difference.
    </p>
    <p>
      Over the past <strong>10 years</strong>, I’ve worked on creating user-friendly web applications, 
      designing scalable systems, and improving workflows for businesses. I love solving complex problems, 
      learning new things, and working with teams that value <span className="highlight">innovation</span> 
      and <span className="highlight">collaboration</span>.
    </p>
    <p>
      If you’re looking for someone who can bring fresh ideas, write clean and efficient code, 
      and help your projects succeed, I’d love to hear from you. Let’s create something amazing together!
    </p>
        <div className="contact-button">
          <Link to="/contact">
            <button className="contact-link-button">Go to Contact Form</button>
          </Link>
        </div>
        <div className={`contact-info fade-in-element ${isInfoVisible ? 'active' : ''}`}>
          <ul>
            <li>
              <span className="icon linkedin-icon"></span>
              <a
                href="https://www.linkedin.com/in/aleksei-aleinikov-78195911a/"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>
            </li>
            <li>
              <span className="icon github-icon"></span>
              <a
                href="https://github.com/LF3551"
                target="_blank"
                rel="noopener noreferrer"
              >
                GitHub
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HireMe;
