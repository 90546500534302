// Resume.js
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../Styles/Resume.css';
import my_image from '../images/my_photo.jpg';
import email from '../icons/email.svg';
import linkedin from '../icons/linkedin.svg';
import github from '../icons/github.svg';
import medium from '../icons/medium.png';

const getPlatformIcon = (platform) => {
  switch (platform) {
    case 'Udemy':
      return '/udemy.png';
    case 'Coursera':
      return '/coursera.png';
    case 'PyNEng':
      return '/pyneng.png';
    case 'ServiceNow':
      return '/now.png';
    case 'Juniper Networks':
      return '/juniper.png';
    case 'FastLane':
      return '/huawei.svg';
    case 'Microtest':
      return '/huawei.svg';
    case 'TuneIT':
      return '/huawei.svg';
    // Добавьте больше платформ по необходимости
    default:
      return 'https://via.placeholder.com/40'; // Заглушка для платформ без иконок
  }
};



const Resume = ({ title, details, skills, experience, education, publications, courses, honors_awards, certifications, pet_projects }) => {
  // Для модального окна курсов
  const [isCourseModalOpen, setCourseModalOpen] = useState(false);
  const [currentCourseImage, setCurrentCourseImage] = useState("");

  // Для модального окна проектов
  const [isProjectModalOpen, setProjectModalOpen] = useState(false);
  const [currentProjectImages, setCurrentProjectImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // New modal state for awards
  const [isAwardModalOpen, setAwardModalOpen] = useState(false);
  const [currentAwardImage, setCurrentAwardImage] = useState("");

  // New modal state for certs
  const [isCertificationModalOpen, setCertificationModalOpen] = useState(false);
  const [currentCertificationImage, setCurrentCertificationImage] = useState("");

  const [fadeIn, setFadeIn] = useState(false);
  const location = useLocation();
  const [visibleProjects, setVisibleProjects] = useState(2);
  const [visibleCourses, setVisibleCourses] = useState(4);
  const [visiblePublications, setVisiblePublications] = useState(2);
  const [visibleCertifications, setVisibleCertifications] = useState(2); 
  const [visibleExperiences, setVisibleExperiences] = useState(2);
  // Включаем анимацию при загрузке компонента
  useEffect(() => {
    setFadeIn(false); // Сбрасываем анимацию
    const timer = setTimeout(() => setFadeIn(true), 100); // Включаем анимацию с задержкой
    return () => clearTimeout(timer); // Очищаем таймер при размонтировании
  }, [location.pathname]);


  // Функции для работы с модальным окном сертификатов
  const handleOpenCertificationModal = (image) => {
    setCurrentCertificationImage(image);
    setCertificationModalOpen(true);
  };

  const handleCloseCertificationModal = () => {
    setCertificationModalOpen(false);
    setCurrentCertificationImage("");
  };

  // Открыть модальное окно курсов
  const handleOpenCourseModal = (image) => {
    setCurrentCourseImage(image);
    setCourseModalOpen(true);
  };

  // Закрыть модальное окно курсов
  const handleCloseCourseModal = () => {
    setCourseModalOpen(false);
    setCurrentCourseImage("");
  };

  // Открыть модальное окно проектов
  const handleOpenProjectModal = (images) => {
    setCurrentProjectImages(images);
    setCurrentImageIndex(0);
    setProjectModalOpen(true);
  };

  // Закрыть модальное окно проектов
  const handleCloseProjectModal = () => {
    setProjectModalOpen(false);
    setCurrentProjectImages([]);
  };

  // Показать следующее изображение проекта
  const handleNextProjectImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % currentProjectImages.length);
  };

  // Показать предыдущее изображение проекта
  const handlePrevProjectImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + currentProjectImages.length) % currentProjectImages.length);
  };

  // Open the award modal
  const handleOpenAwardModal = (image) => {
    setCurrentAwardImage(image);
    setAwardModalOpen(true);
  };

  // Close the award modal
  const handleCloseAwardModal = () => {
    setAwardModalOpen(false);
    setCurrentAwardImage("");
  };
  const handleShowAllProjects = () => {
    setVisibleProjects(pet_projects.length); // Показать все проекты
  };
  
  const handleShowLessProjects = () => {
    setVisibleProjects(2); // Вернуться к начальному значению (2 проекта)
  };
  
  
  const handleShowAllCourses = () => {
    setVisibleCourses(courses.length); // Показать все курсы
  };
  
  const handleShowLessCourses = () => {
    setVisibleCourses(4); // Вернуться к начальному значению
  };
  const handleShowAllPublications = () => {
    setVisiblePublications(publications.length); // Показать все публикации
  };
  
  const handleShowLessPublications = () => {
    setVisiblePublications(2); // Вернуться к начальному значению
  };
  const handleShowAllCertifications = () => {
    setVisibleCertifications(certifications.length); // Показать все сертификаты
  };
  
  const handleShowLessCertifications = () => {
    setVisibleCertifications(2); // Вернуться к начальному значению
  };
  const handleShowAllExperience = () => {
    setVisibleExperiences(experience.length); // Показать все элементы
  };
  
  const handleShowLessExperience = () => {
    setVisibleExperiences(2); // Вернуть к начальному значению
  };
  return (
    <div className={`resume ${fadeIn ? 'fade-in' : ''}`}>
      <h2>{title}</h2>
      <img src={my_image} alt={`${title} profile`} className="profile-image" />
      <div className="details">
        <h3>About Me</h3>
        <p>{details}</p>

        <h3>Contact</h3>
        <div className="contact-info">
          <p>
            <a href="mailto:contact@alekseialeinikov.com" className="contact-link">
              <img src={email} alt="Email" className="contact-icon" />
            </a>
          </p>
          <p>
            <a href="https://www.linkedin.com/in/aleksei-aleinikov-78195911a/" target="_blank" rel="noopener noreferrer" className="contact-link">
              <img src={linkedin} alt="LinkedIn" className="contact-icon" />
            </a>
          </p>
          <p>
            <a href="https://github.com/LF3551" target="_blank" rel="noopener noreferrer" className="contact-link">
              <img src={github} alt="GitHub" className="contact-icon" />
            </a>
          </p>
        </div>


        <h3>Skills</h3>
        <div className="skills-grid">
          {skills.map(skill => (
            <div className="skill-item" key={skill}>
              {skill}
            </div>
          ))}
        </div>


































        <h3>Experience</h3>
<div className="experience-container">
  {experience && experience.length > 0 ? (
    <>
      {experience.slice(0, visibleExperiences).map((job, index) => (
        <div key={index} className="experience-card">
          {/* Таймлайн слева */}
          <div className="timeline">
            <div className={`timeline-marker ${index === 0 ? 'active' : ''}`}></div>
            {index !== experience.length - 1 && <div className="timeline-line"></div>}
          </div>
          {/* Контент карточки */}
          <div className="experience-content">
            <h4 className="job-title">{job.title}</h4>
            <p className="job-company">
              {job.company} · {job.employment_type}
            </p>
            <p className="job-dates">
              {job.dates.start} - {job.dates.end} · {job.dates.duration}
            </p>
            <p className="job-location">
              {job.location.city}, {job.location.region}, {job.location.country} · {job.location.type}
            </p>
            <p
              className="job-description"
              dangerouslySetInnerHTML={{
                __html: job.description.replace(/\n/g, '<br />'),
              }}
            ></p>
          </div>
        </div>
      ))}
      {experience.length > 2 && (
        <div className="toggle-experience">
          {visibleExperiences < experience.length ? (
            <button onClick={handleShowAllExperience} className="show-all-button">
              Show All ({experience.length})
            </button>
          ) : (
            <button onClick={handleShowLessExperience} className="show-less-button">
              Show Less
            </button>
          )}
        </div>
      )}
    </>
  ) : (
    <p className="no-experience">No experience available.</p>
  )}
</div>



































        <h3>Projects</h3>
<div className="pet-projects-container">
  {pet_projects && pet_projects.length > 0 ? (
    <>
      {pet_projects.slice(0, visibleProjects).map((project, index) => (
        <div key={index} className="pet-project-card">
          <div className="pet-project-content">
            <div className="project-images-container">
              {project.images &&
                project.images.map((image, imgIndex) => (
                  <img
                    key={imgIndex}
                    src={image || "https://via.placeholder.com/150"}
                    alt={`${project.title} screenshot`}
                    className="project-image-thumbnail"
                    onClick={() => handleOpenProjectModal(project.images, imgIndex)}
                  />
                ))}
            </div>
            <div className="project-info">
              <h4 className="project-title">
                <a href={project.link} target="_blank" rel="noopener noreferrer">
                  {project.title}
                </a>
              </h4>
              <p className="project-description">{project.description}</p>
              <p className="project-technologies">
                {project.technologies.map((tech, index) => (
                  <span key={index}>{tech}</span>
                ))}
              </p>
              <p className="project-date">Date: {project.date}</p>
            </div>
          </div>
        </div>
      ))}
      {pet_projects.length > 2 && (
        <div className="toggle-projects">
          {visibleProjects < pet_projects.length ? (
            <button onClick={handleShowAllProjects} className="show-all-button">
              Show All ({pet_projects.length})
            </button>
          ) : (
            <button onClick={handleShowLessProjects} className="show-less-button">
              Show Less
            </button>
          )}
        </div>
      )}
    </>
  ) : (
    <p>No pet projects available.</p>
  )}
</div>


        {/* Modal for Image Carousel */}
        {isProjectModalOpen && (
          <div className="modal" onClick={handleCloseProjectModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <span className="close-button" onClick={handleCloseProjectModal}>&times;</span>
              <button className="prev-button" onClick={handlePrevProjectImage}>&#10094;</button>
              <img src={currentProjectImages[currentImageIndex]} alt="Project Screenshot" className="modal-image" />
              <button className="next-button" onClick={handleNextProjectImage}>&#10095;</button>
            </div>
          </div>
        )}





























<h3>Licenses & Certifications</h3>
<div className="certifications-container">
  {certifications && certifications.length > 0 ? (
    <>
      {certifications.slice(0, visibleCertifications).map((cert, index) => (
        <div className="certification-card" key={index}>
          <div className="cert-content">
            <div className="cert-header">
              <img
                src={cert.organizationIcon}
                alt={`${cert.issuedBy} logo`}
                className="organization-icon"
              />
              <h4 className="cert-title">{cert.title}</h4>
            </div>
            <p className="certification-issued">
              Issued by {cert.issuedBy} - {cert.date}
            </p>
            <p className="credential-id">Credential ID: {cert.credentialId}</p>
            <p className="certification-skills">
              Skills: {cert.skills.join(", ")}
            </p>
            {cert.link && (
              <a
                href={cert.link}
                target="_blank"
                rel="noopener noreferrer"
                className="show-credential"
              >
                Show credential
              </a>
            )}
          </div>
          {cert.certificateImage && (
            <div
              className="cert-image-container"
              onClick={() => handleOpenCertificationModal(cert.certificateImage)}
            >
              <img
                src={cert.certificateImage}
                alt={`Certificate for ${cert.title}`}
                className="cert-image-thumbnail"
              />
            </div>
          )}
        </div>
      ))}
      {certifications.length > 2 && (
        <div className="toggle-certifications">
          {visibleCertifications < certifications.length ? (
            <button onClick={handleShowAllCertifications} className="show-all-button">
              Show All ({certifications.length})
            </button>
          ) : (
            <button onClick={handleShowLessCertifications} className="show-less-button">
              Show Less
            </button>
          )}
        </div>
      )}
    </>
  ) : (
    <p>No certifications available.</p>
  )}
</div>



        {/* Certification modal */}
        {isCertificationModalOpen && (
          <div className="modal" onClick={handleCloseCertificationModal}>
            <div className="modal-content">
              <span className="close-button" onClick={handleCloseCertificationModal}>&times;</span>
              <img
                src={currentCertificationImage}
                alt="Certification"
                className="modal-image"
                onClick={handleCloseCertificationModal}  // Добавляем обработчик на изображение
              />
            </div>
          </div>
        )}



























<h3>Courses</h3>
<div className="courses-container">
  {courses && courses.length > 0 ? (
    <>
      {courses.slice(0, visibleCourses).map((course, index) => (
        <div key={index} className="course-card">
          <img
            src={getPlatformIcon(course.platform)}
            alt={course.platform}
            className="course-icon"
          />
          <div className="course-content">
            <h4 className="course-title">{course.title}</h4>
            <p className="course-platform">{course.platform}</p>
            <p className="course-year">{course.year}</p>
            {course.image && (
              <div className="certificate-container">
                <div
                  className="certificate-thumbnail"
                  onClick={() => handleOpenCourseModal(course.image)}
                >
                  <img
                    src={course.image}
                    alt={`Certificate for ${course.title}`}
                    className="certificate-thumbnail-image"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
      {courses.length > 4 && (
        <div className="toggle-courses">
          {visibleCourses < courses.length ? (
            <button onClick={handleShowAllCourses} className="show-all-button">
              Show All ({courses.length})
            </button>
          ) : (
            <button onClick={handleShowLessCourses} className="show-less-button">
              Show Less
            </button>
          )}
        </div>
      )}
    </>
  ) : (
    <p className="no-courses">No courses available.</p>
  )}
</div>


        {/* Модальное окно для курсов */}
        {isCourseModalOpen && (
          <div className="modal" onClick={handleCloseCourseModal}>
            <div className="modal-content">
              <span className="close-button" onClick={handleCloseCourseModal}>&times;</span>
              <img src={currentCourseImage} alt="Certificate" className="modal-image" />
            </div>
          </div>
        )}































        <h3>Education</h3>
        <div className="education-container">
          {education.map((edu, index) => (
            <div key={index} className="education-card">
              <h4>{edu.institution}</h4>
              <p>{edu.degree}</p>
              <p>{edu.years}</p>
              <h5>Skills:</h5>
              <p>{edu.skills.join(' · ')}</p>
            </div>
          ))}
        </div>


















































        <h3>Publications</h3>
<div className="publications-container">
  {publications && publications.length > 0 ? (
    <>
      {publications
        .sort((a, b) => new Date(b.date) - new Date(a.date)) // Сортировка по убыванию даты
        .slice(0, visiblePublications)
        .map((pub, index) => (
          <div key={index} className="publication-card">
            <div className="publication-header">
              <img src={medium} alt="Medium" className="medium-icon" />
              <h4 className="publication-title">{pub.title}</h4>
            </div>
            <p className="publication-meta">{pub.platform} · {pub.date}</p>
            <p className="publication-description">{pub.description}</p>
            <a href={pub.url} target="_blank" rel="noopener noreferrer" className="publication-link">
              Show publication
            </a>
          </div>
        ))}
      {publications.length > 3 && (
        <div className="toggle-publications">
          {visiblePublications < publications.length ? (
            <button onClick={handleShowAllPublications} className="show-all-button">
              Show All ({publications.length})
            </button>
          ) : (
            <button onClick={handleShowLessPublications} className="show-less-button">
              Show Less
            </button>
          )}
        </div>
      )}
    </>
  ) : (
    <p className="no-publications">No publications available.</p>
  )}
</div>






























        <h3>Honors and Awards</h3>
        <div className="awards-container">
          {honors_awards && honors_awards.length > 0 ? (
            honors_awards.map((award, index) => (
              <div className="award-card" key={index}>
                <div className="award-header">
                  {award.icon && (
                    <img src={award.icon} alt={`${award.title} icon`} className="award-icon" />
                  )}
                  {award.link ? (
                    <a href={award.link} target="_blank" rel="noopener noreferrer" className="award-title">
                      {award.title}
                    </a>
                  ) : (
                    <span className="award-title">{award.title}</span> // Отображение названия как текст, если ссылки нет
                  )}
                </div>
                <p className="award-issued">Issued by {award.issuedBy} - {award.date}</p>
                <p className="award-category">{award.category}</p>
                <p className="award-description">{award.description}</p>
                {award.certificateImage && (
                  <div className="award-image-container" onClick={() => handleOpenAwardModal(award.certificateImage)}>
                    <img src={award.certificateImage} alt={`Certificate for ${award.title}`} className="award-thumbnail" />
                  </div>
                )}
              </div>
            ))
          ) : (
            <p className="no-awards">No honors and awards available.</p>
          )}
        </div>

        {/* Модальное окно для изображения сертификата */}
        {isAwardModalOpen && (
          <div className="modal" onClick={handleCloseAwardModal}>
            <div className="modal-content">
              <span className="close-button" onClick={handleCloseAwardModal}>&times;</span>
              <img src={currentAwardImage} alt="Award Certificate" className="modal-image" />
            </div>
          </div>
        )}











































      </div>
    </div>
  );
};

export default Resume;