// Highlights.js
import React, { useState, useEffect, useRef } from "react";
import Sidebar from './Sidebar';
import '../Styles/Highlights.css';

const Highlights = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [hoveredCertificate, setHoveredCertificate] = useState(null); 
  const [hoverPosition, setHoverPosition] = useState({ x: 0, y: 0 }); 
  const debounceTimeout = useRef(null); 

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), 100);
    return () => clearTimeout(timer);
  }, []);


  const handleMouseEnter = (imgSrc, event) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
  
    debounceTimeout.current = setTimeout(() => {
      const { clientX, clientY } = event;
      const scrollY = window.scrollY;
  
      // Рассчитываем позиции окна с учетом границ экрана
      const adjustedX = Math.min(clientX, window.innerWidth - 300); // Учитываем ширину окна, чтобы не выйти за правую границу
      const adjustedY = Math.min(clientY + scrollY, window.innerHeight + scrollY - 300); // Учитываем высоту окна
  
      setHoveredCertificate(imgSrc);
      setHoverPosition({ x: adjustedX, y: adjustedY });
    }, 250);
  };
  
  
  const handleMouseLeave = () => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    setHoveredCertificate(null);
  };
  
const certificates = [
  {
    imgSrc: "certifications/certificate1.jpeg",
    title: "Business Intelligence & Data Visualization (Aug 2023 - Oct 2023)",
    description:
      "Specialized in Power BI, Python for Data Analysis, and SQL. Developed expertise in data visualization, statistical analysis, and data wrangling.",
    project: 'Final Project: "The Downhill Sisters"',
  },
  {
    imgSrc: "certifications/certificate2.jpeg",
    title: "Data Analysis (Oct 2023 - Dec 2023)",
    description:
      "Advanced knowledge in Inferential Statistics, Machine Learning, and NLP. Proficient in creating predictive models and applying deep learning techniques.",
    project: 'Final Project: "Productivity in the Garment Industry"',
  },
  {
    imgSrc: "certifications/certificate3.jpeg",
    title: "Data Engineering (Jan 2024 - Mar 2024)",
    description:
      "Expertise in Data Engineering, Google Cloud, and DevOps. Specialized in big data infrastructure design, AI-driven analytics, and machine learning integration.",
    project: 'Final Project: "StockPredict: Navigating the Market with Precision"',
  },
];
  

  return (
    <div className="home">
      <Sidebar />
      <div className={`highlights-content fade-in ${isVisible ? 'active' : ''}`}>
        <h2>Highlights</h2>
        <p className="intro">
          Sharing knowledge and contributing to the tech community has always been a core part of my journey.
        </p>





        <div className="highlights-section">
        <h3>Specialisations</h3>
<p>
    I have completed specialized training and certification programs in the following fields. These programs, hosted on Coursera, reflect my commitment to continuous learning and development in the tech industry.
</p>
<p>
    <span style={{ fontWeight: 'bold', color: '#2A7B4F', textDecoration: 'underline' }}>
        Recognized by 49 countries in the European Higher Education Area (ECTS)
    </span>
    {' '} and {' '}
    <span style={{ fontWeight: 'bold', color: '#1E4D9D', textDecoration: 'underline' }}>
        over 440 institutions in the U.S. (ACE®)
    </span>, these certifications highlight my academic rigor and practical expertise.
</p>
<p>
    They are <span style={{ fontWeight: 'bold', color: '#E85D04' }}>highly valuable</span> and <span style={{ fontWeight: 'bold', color: '#E85D04' }}>transferable</span> across academic and professional settings worldwide.
</p>





 
 
 
 













 
 
<div className="specialisations">

<div className="specialisation-item">
  <a href="https://www.credly.com/badges/811b4596-b626-43b8-a1e9-a0b5c88071fd" target="_blank" rel="noopener noreferrer">
    <img
      src="certifications/google_it_automation.png"
      alt="Google IT Automation"
      onDragStart={(e) => e.preventDefault()}
      onContextMenu={(e) => e.preventDefault()}
    />
  </a>
  <a
    href="https://www.credly.com/badges/811b4596-b626-43b8-a1e9-a0b5c88071fd"
    target="_blank"
    rel="noopener noreferrer"
    style={{ textDecoration: 'none', color: 'inherit' }}
  >
    <h4>Google IT Automation with Python (2023)</h4>
    
  </a>
  <p>
    Focused on <strong>IT automation</strong> using <strong>Python</strong> and <strong>Git</strong>. Skilled in <strong>automating IT processes</strong> and <strong>system administration</strong>. Strong knowledge in <strong>troubleshooting</strong>, <strong>infrastructure management</strong>, and <strong>operating systems</strong>.
  </p>
  <p><strong>ECTS Credits:</strong> 5</p>
</div>


<div className="specialisation-item">
  <a href="https://www.credly.com/badges/ff532d72-7b04-465a-8a5e-9654b37d28db" target="_blank" rel="noopener noreferrer">
    <img
      src="certifications/ibm_full_stack.png"
      alt="IBM Full Stack"
      onDragStart={(e) => e.preventDefault()}
      onContextMenu={(e) => e.preventDefault()}
    />
  </a>
  
  <a
    href="https://www.credly.com/badges/ff532d72-7b04-465a-8a5e-9654b37d28db"
    target="_blank"
    rel="noopener noreferrer"
    style={{ textDecoration: 'none', color: 'inherit' }}
  >
    <h4>IBM Full Stack Software Developer (2024)</h4>
  </a>
  <p>
    Specialized in <strong>full-stack development</strong> with proficiency in <strong>HTML</strong>, <strong>CSS</strong>, <strong>JavaScript</strong>, <strong>Node.js</strong>, <strong>React</strong>, and <strong>Python</strong>. Expertise in <strong>Docker</strong>, <strong>Kubernetes</strong>, <strong>CI/CD pipelines</strong>, <strong>microservices</strong>, and <strong>serverless computing</strong>. Ready to develop and manage <strong>cloud-native applications</strong>.
  </p>
  <p><strong>ECTS Credits:</strong> 6 | <strong>ACE Credits:</strong> 18</p>
</div>


  <div className="specialisation-item">
    <img src="certifications/google_cybersecurity.png" alt="Google Cybersecurity" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} />
    <h4>Google Cybersecurity (2025)</h4>
    <p>
      Advanced training in <strong>cybersecurity</strong> with expertise in <strong>Python</strong>, <strong>Linux</strong>, <strong>SQL</strong>, and tools like <strong>SIEM</strong> and <strong>IDS</strong>. Proficient in <strong>network security</strong>, <strong>threat mitigation</strong>, and the <strong>NIST Cybersecurity Framework</strong>. Prepared for <strong>entry-level cybersecurity roles</strong>.
    </p>
    <p><strong>ECTS Credits:</strong> 7 | <strong>ACE Credits:</strong> 9</p>
  </div>

</div>


</div>









        <div className="highlights-section">
          <h3>Certifications</h3>
          <p>
  My certifications show my <span style={{ fontWeight: 'bold', color: '#146520' }}>dedication to growth</span> and <span style={{ fontWeight: 'bold', color: '#E85D04', textDecoration: 'underline' }}>commitment to learning</span> in the fast-changing tech world. They reflect my <span style={{ fontWeight: 'bold', color: '#1E4D9D' }}>strong skills</span> in <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>networking</span>, <span style={{ fontWeight: 'bold', color: '#2A7B4F' }}>cloud technologies</span>, <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>agile methods</span>, and <span style={{ fontWeight: 'bold', color: '#E85D04' }}>data engineering</span>.
</p>
<p>
  Here are some of the <span style={{ fontWeight: 'bold', color: '#E85D04' }}>key certifications</span> I’ve achieved. They prove my ability to <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>solve tough problems</span> and <span style={{ fontWeight: 'bold', color: '#146520' }}>deliver results</span> that make a difference:
</p>




          <div className="certifications-icons">
            <div className="cert-item" title="Cisco CCNA">
              <img src="/ccna.png" alt="Cisco Certification" className="cert-icon" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
            </div>
            <div className="cert-item" title="Huawei HCIP">
              <img src="/hcip.png" alt="Huawei Certification" className="cert-icon" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
            </div>
            <div className="cert-item" title="SAFE Agile Certified">
              <img src="/SAFE.png" alt="Huawei Certification" className="cert-icon" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
            </div>
            <div className="cert-item" title="Azure Data fundamentals – Associate">
              <img src="/azure.png" alt="Azure Certification" className="cert-icon" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
            </div>
            <div className="cert-item" title="Google Cloud Professional Data Engineer">
              <img src="/CDE.png" alt="Google Cloud Certification" className="cert-icon" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
            </div>

          </div>

        </div>



















        <div className="professional-development-section">
          <h3>Professional Development</h3>
          <p>
  I am committed to growing my skills through <span style={{ fontWeight: 'bold', color: '#146520' }}>professional development</span>. I have completed <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>advanced training</span> in <span style={{ color: '#2A7B4F' }}>data engineering</span>, <span style={{ color: '#2A7B4F' }}>data analysis</span>, and <span style={{ color: '#2A7B4F' }}>business intelligence</span>. These programs have helped me build <span style={{ fontWeight: 'bold', color: '#E85D04' }}>strong expertise</span> in technologies like <span style={{ textDecoration: 'underline', color: '#1E4D9D' }}>Power BI</span>, <span style={{ textDecoration: 'underline', color: '#1E4D9D' }}>Python</span>, <span style={{ textDecoration: 'underline', color: '#1E4D9D' }}>SQL</span>, and <span style={{ textDecoration: 'underline', color: '#1E4D9D' }}>machine learning</span>.
</p>
<p>
  Through these <span style={{ fontWeight: 'bold', color: '#E85D04' }}>training courses</span>, I’ve sharpened my skills in <span style={{ fontWeight: 'bold', color: '#146520' }}>data visualization</span>, <span style={{ fontWeight: 'bold', color: '#146520' }}>statistical analysis</span>, <span style={{ fontWeight: 'bold', color: '#146520' }}>big data infrastructure</span>, and <span style={{ fontWeight: 'bold', color: '#146520' }}>AI-powered analytics</span>. These experiences have prepared me to handle <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>complex data challenges</span> and deliver impactful solutions in the <span style={{ color: '#2A7B4F' }}>data field</span>.
</p>


          <div className="certificates-container">
  {certificates.map((cert, index) => (
    <div
  key={index}
  className="specialisation-item "
  onMouseEnter={(e) => handleMouseEnter(cert.imgSrc, e)} // Наведение
  onMouseLeave={(e) => {
    const relatedTarget = e.relatedTarget;

    // Проверяем, не является ли relatedTarget null и принадлежит ли он DOM-узлу
    if (!relatedTarget || !(relatedTarget instanceof Node) || !e.currentTarget.contains(relatedTarget)) {
      handleMouseLeave(); // Убираем всплывающее окно
    }
  }}
>
  <img src={cert.imgSrc} alt={cert.title} />
  <h4
    dangerouslySetInnerHTML={{
      __html: cert.title.replace(/\(/g, "<br />("),
    }}
  ></h4>
  <p>{cert.description}</p>
  <p>{cert.project}</p>
</div>

  ))}
</div>

        </div>

        {/* Всплывающее увеличенное изображение */}
        {hoveredCertificate && (
         <div
         className={`hover-overlay ${hoveredCertificate ? 'active' : ''}`}
         style={{
          position: "absolute",
          top: hoverPosition.y + 1520, // Появляется чуть ниже курсора
          left: hoverPosition.x - 520, // Появляется чуть правее курсора
          zIndex: 9999,
          background: "white",
          borderRadius: "12px",
          boxShadow: "0 4px 15px rgba(0, 0, 0, 0.3)",
          padding: "10px",
          transform: "translate(-50%, -50%)",
        }}
       >
         <img
           src={hoveredCertificate}
           alt="Hovered Certificate"
           style={{
             maxWidth: "1300px",
             maxHeight: "1300px",
             borderRadius: "8px",
           }}
         />
       </div>
        )}




  
















 






        <div className="highlights-section">
  <h3>Google Cloud Achievements</h3>
  <p>
    I have been an active member of <span className="highlight">Google Cloud Skills Boost</span> since 2022, earning a place in the <strong>Diamond League</strong> with over <strong>120,000 points</strong>. Over this time, I have completed more than 1,000 hands-on labs, gaining extensive expertise in Google Cloud Platform (GCP) services.
  </p>
  <div className="gcp-profile">
    <div className="gcp-details">
      <p>
        My achievements showcase my advanced skills in application modernization, data analytics, and cloud infrastructure. You can view my full profile and achievements on Google Cloud Skills Boost:
      </p>
      <a
        href="https://www.cloudskillsboost.google/public_profiles/3f0276b2-6903-48d5-adf1-8831d5555c7e"
        target="_blank"
        rel="noopener noreferrer"
        className="gcp-link"
      >
        View My Google Cloud Profile
      </a>
    </div>
    <div className="gcp-image">
      <img src="/gcp_skill.png" alt="Google Cloud Diamond League Badge" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
    </div>
  </div>
</div>












<div className="highlights-section">
          <h3>Technical Publications</h3>
          <p>
            I have had the privilege of publishing technical articles on some of the most reputable platforms in the developer community, including:
          </p>
          <ul className="publication-links">
            <li>
              <a href="https://blog.devgenius.io" target="_blank" rel="noopener noreferrer">Dev Genius</a>
            </li>
            <li>
              <a href="https://medium.datadriveninvestor.com" target="_blank" rel="noopener noreferrer">DataDrivenInvestor</a>
            </li>
            <li>
              <a href="https://javascript.plainenglish.io" target="_blank" rel="noopener noreferrer">JavaScript Plain English</a>
            </li>
            <li>
              <a href="https://medium.com/pythoneers" target="_blank" rel="noopener noreferrer">Pythoneers</a>
            </li>
            <li>
              <a href="https://levelup.gitconnected.com" target="_blank" rel="noopener noreferrer">Level Up Coding</a>
            </li>
          </ul>
          <p>
            These articles reflect my expertise in JavaScript, Python, and Full-Stack Development, as well as my ability to explain complex concepts in an accessible way.
          </p>
        </div>
























<div className="highlights-section book-section">
  <h3>Published Book</h3>
  <p>
  In 2024, I proudly released my first book, <span className="highlight">"Code Your Own Path"</span>, a culmination of 11 months of dedicated work. This practical guide is tailored for aspiring developers, covering essential topics such as coding skills, personal growth, time management, and networking. 
  The book is designed to empower developers to build a successful tech career on their own terms.
</p>

  <div className="book-content">
    <div className="book-links-container">
      <ul className="book-links">
        <li>
          <a href="https://www.amazon.com/gp/product/B0DNSLL5BL/ref=x_gr_bb_amazon" target="_blank" rel="noopener noreferrer">
            <div className="platform-card">
              <img src="/Amazon_icon.png" alt="Amazon" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
              <span>Amazon</span>
            </div>
          </a>
        </li>
        <li>
          <a href="https://www.lulu.com/shop/aleksei-aleinikov/code-your-own-path/ebook/product-zm4pmgm.html" target="_blank" rel="noopener noreferrer">
            <div className="platform-card">
              <img src="/lulu_icon.png" alt="Lulu" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
              <span>Lulu</span>
            </div>
          </a>
        </li>
        <li>
          <a href="https://books.apple.com/gr/book/code-your-own-path/id6738574508" target="_blank" rel="noopener noreferrer">
            <div className="platform-card">
              <img src="/apple_books_icon.png" alt="Apple Books" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
              <span>Apple Books</span>
            </div>
          </a>
        </li>
        <li>
          <a href="https://www.kobo.com/gr/en/ebook/code-your-own-path" target="_blank" rel="noopener noreferrer">
            <div className="platform-card">
              <img src="kobo_icon.jpg" alt="Kobo Books" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
              <span>Kobo Books</span>
            </div>
          </a>
        </li>
        <li>
          <a href="https://www.bol.com/nl/nl/p/code-your-own-path/9300000197821825/" target="_blank" rel="noopener noreferrer">
            <div className="platform-card">
              <img src="/bol_icon.png" alt="Bol" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
              <span>Bol</span>
            </div>
          </a>
        </li>
        <li>
          <a href="https://www.everand.com/book/795491183/Code-Your-Own-Path" target="_blank" rel="noopener noreferrer">
            <div className="platform-card">
              <img src="/everand_icon.png" alt="Everand" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
              <span>Everand</span>
            </div>
          </a>
        </li>
        <li>
          <a href="https://www.indigo.ca/fr-ca/code-your-own-path/16f97c8d-5e65-37a2-b3df-7fa7471e1f2a.html" target="_blank" rel="noopener noreferrer">
            <div className="platform-card">
              <img src="/indigo_icon.png" alt="Indigo" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
              <span>Indigo</span>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <div className="book-image">
      <img src="/cyop.png" alt="Code Your Own Path" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()}/>
    </div>
  </div>
</div>















        <div className="highlights-section">
          <h3>Community Engagement</h3>
          <p>
            Over the years, I have demonstrated my passion for continuous learning by initiating and organizing a book club within my organization. These sessions provided a platform for discussing technical, leadership, and productivity topics.
          </p>
          <ul className="engagement-list">
            <li>Organized and led 24+ book club sessions over several years, fostering meaningful discussions and knowledge-sharing.</li>
            <li>
              Delivered presentations as a speaker on influential books, including:
              <ul>
                <li>"The 5 AM Club" by Robin Sharma (focused on personal growth and productivity)</li>
                <li>"Attention Pays" by Neen James (centered on accountability and driving results)</li>
                <li>"The Everyday Hero Manifesto" by Robin Sharma (highlighting self-leadership and making an impact)</li>
              </ul>
            </li>
            <li>Encouraged collaboration and cross-functional engagement, improving team communication and fostering a culture of growth.</li>
          </ul>
          <p>
            These initiatives strengthened my skills in public speaking, mentoring, and inspiring teams, while also promoting a culture of continuous learning and improvement.
          </p>
        </div>













      </div>
    </div>
  );
};

export default Highlights;
