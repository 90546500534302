// Portfolio.js

import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import '../Styles/Portfolio.css';
import { loadData } from '../utils/loaddata';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
const Portfolio = () => {
  const [isPageVisible, setIsPageVisible] = useState(false);
  const [petProjects, setPetProjects] = useState([]);
  const [uniqueTechnologies, setUniqueTechnologies] = useState([]);
  const [selectedTech, setSelectedTech] = useState([]);
  const [architectures, setArchitectures] = useState([
    {
      image: 'architecture/backup-restore-architecture.png',
      title: 'Backup and Restore',
      description:
        'Implemented a robust multi-region backup and restore architecture leveraging AWS services like S3 Cross-Region Replication, Aurora Global Database, and EBS Snapshots. Ensured data integrity, high availability, and disaster recovery for critical workloads.',
      tags: ['AWS', 'S3', 'Aurora', 'EBS', 'Disaster Recovery'],
    },
    {
      image: 'architecture/pilot-light-architecture.png',
      title: 'Pilot Light Architecture',
      description:
        'Implemented a pilot light disaster recovery approach using AWS Aurora Global Database, Elastic Load Balancing, and Auto Scaling groups. This architecture ensures critical resources like databases are always on while application servers remain idle until failover, reducing costs and recovery time.',
      tags: ['AWS', 'Aurora', 'Elastic Load Balancing', 'Auto Scaling', 'Disaster Recovery'],
    },
    {
      image: 'architecture/cicd-ml-architecture.png',
      title: 'CI/CD for ML with Vertex AI Pipelines',
      description:
        'Implemented a CI/CD architecture for ML pipelines using Google Cloud Build and Vertex AI Pipelines. This system automates Docker image creation, artifact registry management, and pipeline deployments triggered by GitHub or Bitbucket commits. It ensures seamless integration and deployment of ML models, from data extraction to serving predictions.',
      tags: ['Google Cloud', 'Vertex AI', 'Cloud Build', 'CI/CD', 'MLOps'],
    },
    
    {
      image: 'architecture/warm-standby-architecture.png',
      title: 'Warm Standby Architecture',
      description:
        'Developed a warm standby architecture using AWS Aurora Global Database, Elastic Load Balancing, and Auto Scaling groups. This approach maintains a scaled-down but fully functional environment in a secondary region, ensuring rapid failover and reduced recovery time during disaster scenarios.',
      tags: ['AWS', 'Aurora', 'Elastic Load Balancing', 'Auto Scaling', 'Disaster Recovery'],
    },    
    {
      image: 'architecture/cloud-vpn-architecture.svg',
      title: 'Cloud VPN Hub-and-Spoke Architecture',
      description:
        'Developed a scalable hub-and-spoke architecture using Google Cloud VPN to overcome VPC Network Peering limitations. This design supports transitive traffic across multiple VPC networks while ensuring secure and reliable connectivity between spokes via the central hub.',
      tags: ['Google Cloud', 'Cloud VPN', 'Hub-and-Spoke', 'VPC', 'Networking'],
    },
    {
      image: 'architecture/hub-spoke-architecture-vpc-peering.svg',
      title: 'VPC Network Peering Hub-and-Spoke Architecture',
      description:
        'Designed a hub-and-spoke network using VPC Network Peering to enable secure and efficient communication between resources in separate VPC networks. Traffic stays entirely on Google’s internal network, leveraging Cloud NAT for outbound internet access. For enhanced connectivity between spokes, Cloud VPN is implemented to forward routes where needed.',
      tags: ['Google Cloud', 'VPC Network Peering', 'Hub-and-Spoke', 'Cloud NAT', 'Cloud VPN', 'Networking'],
    },
    {
      image: 'architecture/enterprise-app-oracle-database-compute-engine.svg',
      title: 'Enterprise App with Oracle Database on Compute Engine',
      description:
        'Implemented a multi-tier architecture for an enterprise application using Oracle Database on Compute Engine VMs. This architecture features regional managed instance groups, load balancing, and Oracle Data Guard for high availability. Cloud NAT, Hyperdisk storage, and Cloud Storage are used for connectivity, storage efficiency, and backups.',
      tags: [
        'Google Cloud',
        'Oracle Database',
        'Compute Engine',
        'Cloud NAT',
        'Data Guard',
        'Hyperdisk',
        'Cloud Storage',
        'Load Balancing',
      ],
    },
    
    
  ]);

  useEffect(() => {
    const pageTimer = setTimeout(() => setIsPageVisible(true), 100);
    return () => clearTimeout(pageTimer);
  }, []);
  
  const toggleSelectTech = (tech) => {
    setSelectedTech((prev) =>
      prev.includes(tech) ? prev.filter((t) => t !== tech) : [...prev, tech]
    );
  };
  useEffect(() => {
    async function fetchData() {
      setPetProjects([]); // Очистка предыдущих данных
      const allData = await loadData();
      const allProjects = allData.flatMap((file) => file.pet_projects || []);
      const uniqueProjects = Array.from(
        new Map(
          allProjects.map((project) => [
            `${project.title}_${project.description}`,
            project
          ])
        ).values()
      );
      setPetProjects(uniqueProjects);
  
      const allTechnologies = uniqueProjects.flatMap((project) => project.technologies || []);
      setUniqueTechnologies(Array.from(new Set(allTechnologies)));
    }
  
    fetchData();
  }, []);
  
  


  return (
    <div className="home">
      <Sidebar />
      <div className={`portfolio-content fade-in-page ${isPageVisible ? 'active' : ''}`}>
        <h2>Portfolio</h2>
        <p className="portfolio-intro">
          Explore a curated collection of my projects and achievements, showcasing my expertise in Full-Stack Development, DevOps, Python Optimization, and more.
        </p>






 {/* Architectural Designs Section */}
 <div className="architecture-section">
 <h3 className="section-heading">Architectural Designs</h3>
<p className="section-description">
  I specialize in designing and deploying complex systems that are scalable, reliable, and tailored for enterprise needs. From multi-region setups to event-driven architectures and CI/CD pipelines, I ensure these systems are fully implemented, optimized, and ready for real-world use.
</p>

          <div className="architecture-carousel">
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              responsive={{
                superLargeDesktop: {
                  breakpoint: { max: 4000, min: 1024 },
                  items: 3,
                },
                desktop: {
                  breakpoint: { max: 1024, min: 768 },
                  items: 2,
                },
                tablet: {
                  breakpoint: { max: 768, min: 464 },
                  items: 1,
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                },
              }}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              customTransition="all 1.5s"
              transitionDuration={1500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={['tablet', 'mobile']}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              {architectures.map((architecture, index) => (
                <div className="architecture-card" key={index}>
                  <div className="architecture-image">
                    <img
                      src={architecture.image}
                      alt={architecture.title}
                      onDragStart={(e) => e.preventDefault()}
                      onContextMenu={(e) => e.preventDefault()}
                    />
                  </div>
                  <div className="architecture-content">
                    <h4>{architecture.title}</h4>
                    <p>{architecture.description}</p>
                    <div className="architecture-tags">
                      {architecture.tags.map((tag, tagIndex) => (
                        <span key={tagIndex}>{tag}</span>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>












        <div className="portfolio-section">
        <h3 className="section-heading">Enterprise-Grade Solutions</h3>
<p className="section-description">
  This section highlights my professional experience in building high-quality solutions for enterprise needs, including DevOps automation, AI-driven tools, and scalable system optimizations.
</p>


          <div className="portfolio-grid">

          <div className="portfolio-card">
  <div className="card-image">
    <img src="/RAG.png" alt="RAG-Based Chatbot Data Uploader" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} />
  </div>
  <div className="card-content">
    <h4>RAG-Based Chatbot Data Uploader</h4>
    <p>
      <span className="highlight">Developed a sophisticated data uploader</span> for RAG-based chatbots, supporting <span className="highlight-blue">PDF document uploads</span> and employing <span className="highlight-green">adaptive chunking strategies</span>. The system autonomously evaluated and selected optimal chunking methods using <span className="highlight-bold">LLMs</span>, improving chatbot efficiency and response quality while reducing redundancies.
    </p>
    <div className="card-tags">
      <span>Python</span>
      <span>LLM</span>
      <span>RAGAS</span>
      <span>PDF Processing</span>
    </div>
  </div>
</div>

<div className="portfolio-card">
  <div className="card-image">
    <img src="/QA.png" alt="Software Development Quality Platform" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} />
  </div>
  <div className="card-content">
    <h4>Software Development Quality Platform</h4>
    <p>
      Built an <span className="highlight">AI-powered platform</span> using <span className="highlight-blue">neural networks</span> to analyze business requirements, user stories, and test cases. The system identified <span className="highlight-red">missing or redundant elements</span>, estimated test coverage, and provided <span className="highlight-green">actionable insights</span>, improving development efficiency and software quality.
    </p>
    <div className="card-tags">
      <span>Neural Networks</span>
      <span>Python</span>
      <span>Task Optimization</span>
      <span>Test Coverage Analysis</span>
    </div>
  </div>
</div>

<div className="portfolio-card">
  <div className="card-image">
    <img src="/devops.png" alt="GitLab CI/CD and Microservices Deployment" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} />
  </div>
  <div className="card-content">
    <h4>GitLab CI/CD and Microservices Deployment</h4>
    <p>
      Developed robust GitLab CI/CD pipelines tailored for <span className="highlight-green">multiple environments</span>, integrating <span className="highlight-blue">ArgoCD</span> for seamless microservices deployment. Designed and maintained <span className="highlight-bold">Helm charts</span> for deploying complex workloads, including StatefulSets and Deployments. Automated the creation and storage of container images in <span className="highlight">JFrog</span>, enabling artifact-based reporting and audit trails.
    </p>
    <div className="card-tags">
      <span>GitLab CI/CD</span>
      <span>ArgoCD</span>
      <span>Helm</span>
      <span>JFrog</span>
    </div>
  </div>
</div>

<div className="portfolio-card">
  <div className="card-image">
    <img src="/LOG.png" alt="Centralized Log Aggregation System" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} />
  </div>
  <div className="card-content">
    <h4>Centralized Log Aggregation System</h4>
    <p>
      Enhanced a system to aggregate and structure logs from <span className="highlight-blue">multiple sources</span>, transforming raw data into <span className="highlight-green">actionable insights</span> with <span className="highlight-bold">real-time visual dashboards</span>. Optimized processing workflows reduced latency by <span className="highlight-red">50%</span> and improved log query efficiency for enterprise monitoring.
    </p>
    <div className="card-tags">
      <span>Python</span>
      <span>Pandas</span>
      <span>Matplotlib</span>
    </div>
  </div>
</div>

<div className="portfolio-card">
  <div className="card-image">
    <img src="/SN.png" alt="ServiceNow Customization" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} />
  </div>
  <div className="card-content">
    <h4>ServiceNow Optimization and Customization</h4>
    <p>
      Optimized <span className="highlight">ServiceNow scripts</span> and database queries, improving data validation and processing efficiency by <span className="highlight-green">75%</span>. Streamlined workflows enhanced <span className="highlight-green">ITSM performance</span> and reduced system resource utilization.
    </p>
    <div className="card-tags">
      <span>ServiceNow</span>
      <span>JavaScript</span>
      <span>ITSM</span>
    </div>
  </div>
</div>

<div className="portfolio-card">
  <div className="card-image">
    <img src="/WEB.png" alt="Responsive Web Application" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} />
  </div>
  <div className="card-content">
    <h4>Responsive and Scalable Web Application</h4>
    <p>
      Contributed to the development of an <span className="highlight">interactive e-commerce platform</span>, focusing on optimizing the <span className="highlight-blue">user interface</span> to improve responsiveness and usability. Refactored page code to reduce load times and resource usage, resulting in <span className="highlight-green">smoother interactions</span> and faster page performance.
    </p>
    <div className="card-tags">
      <span>React</span>
      <span>Node.js</span>
      <span>GCP</span>
    </div>
  </div>
</div>

<div className="portfolio-card">
  <div className="card-image">
    <img src="/AGGR.png" alt="Advanced Data Aggregation Tool" onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} />
  </div>
  <div className="card-content">
    <h4>Advanced Data Aggregation Optimization</h4>
    <p>
      Enhanced an existing <span className="highlight-green">real-time data aggregation</span> and visualization system by optimizing ETL pipelines and restructuring code, improving processing speeds by <span className="highlight-red">40%</span>. Refined dashboard designs to deliver <span className="highlight-green">more actionable insights</span> for enterprise decision-making.
    </p>
    <div className="card-tags">
      <span>Python</span>
      <span>Pandas</span>
      <span>Flask</span>
      <span>Matplotlib</span>
    </div>
  </div>
</div>




          </div>
        </div>







        <div className="pet-projects-section">
        <h3 className="section-heading">My Projects</h3>
<p className="section-description">
  A showcase of personal and collaborative projects where I applied modern technologies to solve diverse challenges and deliver innovative results.
</p>


          <div className="pet-projects-grid">
            {petProjects.map((project, index) => (
              <div key={index} className="pet-project-card">
                <div className="pet-card-image">
                  {project.images && project.images.length > 0 ? (
                    <img src={project.images[0]} alt={project.title} onDragStart={(e) => e.preventDefault()} onContextMenu={(e) => e.preventDefault()} />
                  ) : (
                    <div className="placeholder-image">No Image</div>
                  )}
                </div>
                <div className="pet-card-content">
                  <h4>{project.title}</h4>
                  <p>{project.description}</p>
                  <div className="pet-card-tags">
                    {project.technologies.map((tech, techIndex) => (
                      <span key={techIndex}>{tech}</span>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>




        <div className="unique-technologies-section">
          <h3 className="section-heading">Unique Technologies</h3>
          <div className="unique-technologies">
            {uniqueTechnologies.map((tech, index) => (
             <span
             key={index}
             className={`tech-tag ${selectedTech.includes(tech) ? 'selected' : ''}`}
             onClick={() => toggleSelectTech(tech)}
           >
             {tech}
           </span>
            ))}
          </div>
        </div>






      </div>
    </div>
  );
};









export default Portfolio;
